<template>
  <div class="container">
    <!-- 占位div -->
    <div :style="{height: $store.state.changeHeight + 'px'}"></div>
    <div class="main">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="125px" class="form">
        <el-form-item label="Order Number" prop="orderNumber">
          <el-input v-model.number="ruleForm.orderNumber" />
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="ruleForm.email" />
        </el-form-item>
        <el-button @click="next()" type="primary" class="nextBtn">NEXT</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { validEmail } from '@/utils/validate'
export default {
  data() {
    // 邮箱验证
    var validateEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please enter email'))
      } else if (!validEmail(value)) {
        callback(new Error('Incorrect email format'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {},
      rules: {
        orderNumber: [
          { required: true, message: 'Order number cannot be empty' },
          { type: 'number', message: 'The order number must be a number' }],
        email: [{ validator: validateEmail, required: true, trigger: 'blur' }]
      }
    }
  },
  methods: {
    next() {

    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .main {
    width: 1200px;
    margin: 0 auto;
  }
}
.form {
  margin: 0 auto;
  width: 600px;
}
.nextBtn {
  width: 100%;
  background-color: #036ce2;
  border-radius: 13px;
}
@media (max-width: 1200px) {
  .container {
    width: 100%;
    .main {
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
}
@media (max-width: 620px) {
  .form {
    width: 100%;
  }
}
</style>